.statisticsContainer {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    height: calc(100% - 35px);
}

.dashboardContainer * {
    color: #555555 !important
}

.statisticsContainer h1,h2,h3,h4,h5,h6 {
    color: #1E2864;
}
.statisticCard {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgb(213 213 213 / 50%);
    border-radius: 10px;
    height: 100%;
    border: 0px;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px 25px;
    gap: 20px;
}

.statisticCard .count-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;
}
.statisticCard .count-container >h5:nth-child(2) {

    color: #6F4CDD;
} 
.statisticCard  h2  {
    color: #6F4CDD;
}
.countText {
    font-size: 12px;
}

.category-counts {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    flex: 1;
}

.category-count-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.category-count-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.category-count-container > h6:first-of-type {
    font-weight: 400;
}

.statistics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.time-selector {
}

.nav-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: var(--theme-color-light);
    margin: 0px 10px;
    cursor: pointer;
}
.nav-link:hover {
    color: var(--theme-color-light);
    text-decoration: underline;
}

@media (max-width: 768px) {
    .statisticsContainer {
        gap: 20px;
        padding: 0px 10px;
        height: unset;
    }

    .statisticCard {
        flex: unset;
    }
}