.featured-container{
  position: relative;
}

.featured-container div.title{
    margin: 15px 0px 10px;
    font-family: "DM Sans";
    color: #1E2864;
    font-weight: bold;
    /* margin-left: 1%; */
    margin-left: 5px;
}

.featured-container .subtitle{
  font-size: 12px;
  font-weight: 400;
}

.featured-container .card{
  /* margin: 10px 20px 30px; */
  /* padding: 15px 20px 20px 20px; */
  padding: 0px;
   background: #FFFFFF;
   /* box-shadow: 0px 13px 20px 2px rgb(167 167 167 / 27%); */
   border: 0px;
}

.featured-container .card h5.card-title{
  font-family: "Poppins";
  font-size: 12px;
}
.featured-container .card p.card-text{
  color: #265376;
font-family: "Poppins";
font-size: 12px;
}

.react-multi-carousel-list{
  position: static !important;
}

.react-multi-carousel-list.container{
  max-width: 100% !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.react-multi-carousel-item  {
  padding: 0px 15px 5px 0px;
}

.react-multiple-carousel__arrow{
  position: absolute;
  right: 0px;
  bottom: -20px;
  border-radius: 5px !important;
}

.featured-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
  right: 0px;
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;
  min-width: 35px;
  min-height: 35px;
}
.featured-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
  left: calc(100% + -80px);
  /* background: #F3ECFF;
  border: 1.94706px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 7.78824px; */

  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;
  
  min-width: 35px;
  min-height: 35px;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before{
  /* color: #CABBFF; */
  color: #EFE7FF;
}

.featured-container li.react-multi-carousel-item{
  /* height: 90%; */
  height: inherit;
}

.featured-container .mem_lg_top1_svg{
  position: absolute;
top: -73%;
left: 25%;
width: 14%;
opacity: 0.4;
}

.featured-container .mem_sm_top1_svg{
  position: absolute;
top: -10%;
right: 11%;
width: 21%;
opacity: 0.4;
}

.featured-container .mem_sm_top2_svg{
  position: absolute;
left: -3%;
bottom: -11%;
width: 10%;
opacity: 0.4;
}


@media (max-width:992px) {
  .featured-container div.title{
    /* margin: 15px 0px 10px; */
      margin: 5px;
      font-family: "DM Sans";
      color: #1E2864;
      font-weight: bold;
      margin-left: 1%;
  }

  .featured-container .subtitle{
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
.featured-container  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
    bottom: -25px;
    left: calc(100% + -70px);
    min-width: 35px;
    min-height: 35px;
  }
.featured-container  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
    bottom: -25px;
    left: calc(100% + -120px);
    min-width: 35px;
    min-height: 35px;
  }
}

@media (max-width: 768px) {
  .react-multiple-carousel__arrow{
    bottom: -65px;
  }
  .featured-container div.title{
    /* margin: 25px 0px 25px; */
      margin: 5px;
  }
}
