.Cdropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .Cdropdown {
    position: relative;
    display: inline-block;
  }
  
  .Cdropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9;
  }
  
  .Cdropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .Cdropdown-content a:hover {background-color: #f1f1f1}
  
  .Cdropdown:hover .Cdropdown-content {
    display: block;
  }
  
  .Cdropdown:hover .Cdropbtn {
    background-color: #3e8e41;
  }