.virtual-guru-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.virtual-guru-container .second-container {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 20px;
  overflow: auto;
}

.virtual-guru-container .first-container {
  display: flex;
  width: 100%;
  gap: 50px;
  align-items: center;
  padding: 0px 100px;
  margin-top: -70px;
}

.virtual-guru-container .first-container > div:first-of-type {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
}

.virtual-guru-container .first-container > div:first-of-type h4 b {
  color: var(--theme-color-light);
}

.virtual-guru-container .first-container > div:first-of-type .img-container {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 9px 6px rgb(213 213 213);
  overflow: hidden;
  background-position:center;
  background-repeat: no-repeat;
  background-size:cover;
}

.virtual-guru-container .first-container .description {
  padding: 0px 30px 0px 0px;
  font-size: 1.2rem;
}

.virtual-guru-container
  .first-container
  > div:first-of-type
  .img-container
  img {
  display: flex;
  width: 100%;
  height: auto;
}


@media (max-width: 1080px) {
  .virtual-guru-container .first-container {
    flex-direction: column;
    padding: 0px 25px;
    margin-top: -20px;
  }

  .virtual-guru-container .second-container {
    padding: 0px 15px;
  }
  .virtual-guru-container .first-container .description { 
    padding: 0px;
    text-align: center;
  }
}
