@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.loading{
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.loading div{
  display: block;
  margin: auto;
}

.modal{
  font-family: "DM Sans";
}

.modal-content h2,label{
  color: #1E2864;
  font-weight: 600;
}

.modal-content p{
  color: #3D0CA4;
}

.modal-content a{
  color: #9261EB;
  text-decoration: none;
}

.modal-content button.customBtn{
  font-family: "DM Sans";
  background: linear-gradient(136.89deg, var(--theme-color) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 7.20913px 12.616px rgba(124, 121, 121, 0.25);
  border-radius: 27.0342px;
  padding: 10px 50px;
  color: #fff;
  cursor: pointer;
  border: 0.3px solid grey;
}

.error{
  color: #ff0000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
}
