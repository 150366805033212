#singlePost_desc .ck.ck-editor__main .ck.ck-content.ck-editor__editable{
  height: 200px
}

#singlePost_desc .ck.ck-editor__main .ck.ck-content.ck-editor__editable p{
  color: #000;
}

#singlePost_wwli .ck.ck-editor__main .ck.ck-content.ck-editor__editable{
  height: 100px;
}

#singlePost_wwli .ck.ck-editor__main .ck.ck-content.ck-editor__editable p{
  color: #000;
}
