#home_header .header_top_container h1 {
  font-family: "DM Sans";
  font-weight: 600;
  color: #231b51;
}

#home_header .header_middle_container {
  background: #ffffff;
  border: 1px solid var(--theme-color-light);
  box-sizing: border-box;
  box-shadow: 2px 4px 7px 3px #efe6ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1.5% 1%;
}

#home_header .header_middle_container img.profile_icon_svg {
  width: 75px;
}

#home_header .header_middle_container p {
  margin-bottom: 0;
}

#home_header .text_container {
  width: fit-content;
}

#home_header .header_top_container h1 {
  font-size: calc(34px + (56 - 34) * ((100vw - 300px) / (1600 - 300)));
}

#home_header .text_container h3 {
  width: 500px;
  font-family: "DM Sans";
  font-weight: 700;
  color: #1e2864;
  font-size: calc(20px + (28 - 20) * ((100vw - 300px) / (1600 - 300)));
}

#home_header .text_container p {
  width: calc(200px + (290 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #265376;
}

#home_header .text_container a {
  background: linear-gradient(
    136.89deg,
    var(--theme-color-dark) 46.45%,
    var(--theme-color) 69.27%
  );
  box-shadow: 0px 8px 14px rgba(124, 121, 121, 0.25);
  border-radius: 30px;
  padding: 8px 24px;
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: calc(8px + (14 - 8) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

#home_header .text_container.header_bottom_text_container a {
  margin-top: 20px;
}

#home_header .header_svg1 {
  position: absolute;
  width: 5%;
  top: -138%;
  left: 14%;
  opacity: 0.4;
}

#home_header .header_svg2 {
  position: absolute;
  width: 7%;
  right: -2%;
  top: -111%;
  opacity: 0.4;
}

#home_header .header_svg3 {
  position: absolute;
  right: 0%;
  top: 30%;
  opacity: 0.4;
}

#home_header .header_svg4 {
  position: absolute;
  width: 11%;
  right: 0%;
  top: 23%;
  opacity: 0.4;
}

#home_header .header_svg5 {
  position: absolute;
  width: 5%;
  bottom: -28%;
  left: 0%;
  opacity: 0.4;
}

#home_header .header_svg1_sm {
  position: absolute;
  right: 0%;
  top: -35%;
  width: 34%;
  opacity: 0.4;
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {
  #home_header .container {
    width: 95%;
  }
  #home_header .header_bottom_text_container_col {
    margin-bottom: 1%;
  }
  #home_header .text_container.header_bottom_text_container {
    /* padding-left: 20%; */
    justify-content: flex-end;
  }

  #features_section .feature_card img {
    height: auto;
  }

  #features_section .feature_card p.card-text {
    font-size: 10px;
  }

  #home_header .text_container h3 {
    width: auto;
  }

  #home_header .profile_holder h5 {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  }

  #home_header .profile_holder p {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  }

  #home_header .header_middle_container img.profile_icon_svg {
    width: calc(40px + (50 - 40) * ((100vw - 300px) / (1600 - 300)));
  }

  #home_header .header_svg4 {
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) {
}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {
}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {
}

@media (max-width: 400px) {
  #home_header .header_svg2_sm {
    display: block !important;
    position: absolute;
    left: 0%;
    top: 9%;
    width: 23%;
    opacity: 0.4;
  }

  #home_header .header_bottom_text_container_col {
    justify-content: flex-end !important;
  }

  #home_header .text_container h3 {
    width: calc(150px + (200 - 150) * ((100vw - 300px) / (1600 - 300)));
  }
}
