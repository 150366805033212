@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* overflow-x: hidden; */
  width: 100%;
  height: 100%;
}

body {
  /* position: relative; */
  background: #ffffff;
  /* overflow-x: hidden; */
  padding: 0px !important;
}

div#root {
  height: 100%; /* remove this line to see div.app is no more 100% height */
  min-height: 100vh;
  /* overflow-x: hidden; */
}

div.App {
  height: 100%;
  min-height: 100vh;
  /* overflow-x: hidden; */
}
