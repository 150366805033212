.custom-box {
  padding: 2rem 3rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.text-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
}

.info-box {
  display: flex;
  align-items: end;
  gap: 0.5rem;
  justify-content: start;
}
