.modal-top{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10px;
    background: var(--theme-color);
    border-radius: 6px 6px 0px 0px;
  }
  
  .modal-header{
    border-bottom: 0px !important;
  }
  
  .modal-content{
    background-color: var(--theme-color-light2);
    border: 0px;
  
  }
  
  .modal-body{
    padding: 0px 50px;
    padding-bottom: 50px;
  }
  
  .modal-header button{
    background: none !important;
  }
  .modal-title button span{
    background-color: #D6BFF5;
  }
  
  .modal-dialog{
    max-width: 700px;
  }
  
  @media (max-width: 768px) {
    .modal-body{
      padding: 0px;
    }
  }
  .category-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .category-select {
    width: 300px;

  }