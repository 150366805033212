@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

#backToSignUpText{
  margin-left: 8px !important
}

.modal{
  font-family: "DM Sans";
}
.modal-dialog .modal-dialog-centered{
  position: relative;
}

.modal-content h2,label{
  color: #1E2864;
  font-weight: 600;
}

.modal-content p{
  color: #3D0CA4;
}

.modal-content a{
  color: #9261EB;
  text-decoration: none;
}

.modal-content button.customBtn{
  font-family: "DM Sans";
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 7.20913px 12.616px rgba(124, 121, 121, 0.25);
  border-radius: 27.0342px;
  padding: 10px 50px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: 0px;
}

.spinner_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #7d5b5b96;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.error{
  color: #ff0000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
}
.disabled-btn{
  opacity: 20% !important;
}

@media (max-width: 768px) {
  .modal-content button{
    padding: 5px 20px;
  }
}
