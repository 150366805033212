div#pricingAlertId .MuiAlert-icon .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit{
  color: #fff;
}

.section_title{
    font-family: "DM Sans";
    font-weight: 700;
    color: #1E2864;
}




#pricing_section .container{
  width: 60% !important;
}

#pricing_section .pricing_card_container_col{
  /* margin: 1% -0.5%; */
  margin: 1% 0;
}

#pricing_section .pricing_card.card{
  margin: auto;
  background: #FFFFFF;
  /* border: 2px solid #DCB0FF; */
  box-sizing: border-box;
  box-shadow: 0px 4.44717px 40.0245px rgba(0, 0, 0, 0.1);
  height: 100%;
}

#pricing_section .pricing_card h5.card-title{
  font-weight: 600;
  font-size: 13px;
}

#pricing_section .pricing_card h3{
  font-family: "DM Sans";
  font-size: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 5px 0px !important;
}
#pricing_section .pricing_card .currency{
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 26px;
  margin-right: 10px;
}

#pricing_section .pricing_card .period{
  font-family: "DM Sans";
  font-weight: 300;
  font-size: 13px;
}

#pricing_section .pricing_card p.card-text{
  color: #5B6B82;
  font-family: "DM Sans";
  font-style: normal;
  font-size: 13px;
}

#pricing_section .pricing_card .card-footer{
  background-color: #fff;
  border-top: 0px;
  padding-bottom: 10%;
  text-align: center;
}

#pricing_section .pricing_card .card-footer a{
  border: 1.29234px solidvar(--theme-color);
  box-sizing: border-box;
  border-radius: 22.17px;
  color: #5B6B82;
  text-decoration: none;
  padding: 5% 25%;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 14px;
}

#pricing_section .pricing_card .card-footer a:hover{
  background: linear-gradient(136.89deg, #446DD6 46.45%,var(--theme-color) 69.27%);
  border-radius: 20.17px;
  color: #fff;
}

#pricing_section .price_svg_cloud{
  position: absolute;
  left: -19%;
  top: -22%;
  width: 40%;
  z-index: -100;
}



#pricing_section .price_svg_people_text_container h3{
  width: calc(120px + (360 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "DM Sans";
  font-weight: 700;
  color: #1E2864;
  font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1600 - 300)));
}

#pricing_section .price_svg_people_text_container p{
  width: calc(200px + (290 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Poppins";
  font-weight: 500;
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
  line-height: 22px;
  color: #265376;
}

#pricing_section .price_svg_people_img_container{
  padding-right: 15%;
  padding-left: 10%;
}

#pricing_section .price_svg_people_sm{
  position: absolute;
top: 0%;
right: 8%;
width: 20%;
opacity: 0.4;
}

#pricing_section .pricing_simple_alerts{
   /* width:50%; */
   /* margin-left:auto; */
   margin: auto;
   width: 100%;
}


/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {


  #pricing_section .container{
    width: 100% !important;
  }

  #pricing_section .price_svg_people_text_container_col{
    margin-bottom: 1%;
  }
  #pricing_section .text_container.price_svg_people_text_container{
    padding-left: 5%;
  }
  #pricing_section .price_svg_people_text_container{
    padding-right: 0;
  }

  #pricing_section .pricing_simple_alerts{
     width:100%;
     margin: auto;
  }


}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) and (max-width: 992px) {
  #pricing_section .container{
    width: 90% !important;
  }
}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) and (max-width: 1200px) {
  #pricing_section .container{
    width: 70% !important;
  }
}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}

@media (max-width:500px) {
  #pricing_section .pricing_card h3{
    font-size: 28px;
  }

  #pricing_section .pricing_card .currency{
    font-size: 12px
  }

  #pricing_section .pricing_card .period{
    font-size: 10px;
  }

  #pricing_section .pricing_card .card-footer a{
    padding: 3% 15%;
    font-size: 10px;
  }
}
