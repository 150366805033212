.searchBtn {
  border: 1px solid var(--theme-color-light);
  color: var(--theme-color-light);
  height: 100%;
  border-radius: 0 0.25rem 0.25rem 0;
}

.searchField {
  border: 1px solid var(--theme-color-light);
}

input.searchField {
  margin: 0px !important;
  border-radius: 0.25rem 0 0 0.25rem;
}

.searchField::placeholder {
  color: var(--theme-color-light);
}

.searchBtn:hover {
  background: linear-gradient(
    136.89deg,
    var(--theme-color-dark) 46.45%,
    var(--theme-color) 69.27%
  );
  color: #fff;
}

.searchBtn:active {
  outline: none;
}

.heartBtnFeature {
  padding: 2px;
  background: none;
  border: 0px;
  color: #dd0000;
}

.atcb-button svg {
  background: none;
  border: 0px;
  fill: #dd0000 !important;
}
.atcb-button {
  border: unset !important;
  box-shadow: unset !important;
  background: unset !important;
  display: flex !important;
  align-items: center !important;
  font-family: unset !important;
}

.atcb-text {
  font-weight: 500 !important;
  color: rgb(33, 37, 41) !important;
  font-size: 16px;
}

.atcb-icon {
  height: 16px !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0px !important;
}

hr {
  border-top: 1px solid #fff0;
}

.text_highlight {
  color: #333131;
  font-weight: 700;
}

.section_title {
  font-family: "DM Sans";
  font-weight: 700;
  color: #1e2864;
}

#features_section .feature_card_container_col {
  margin: 2% 0;
}

#features_section .feature_card.card {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(213, 213, 213, 0.5);
  border-radius: 10px;
  height: 100%;
  border: 0px;
  max-width: 18rem;
}

#features_section .feature_card img {
  padding: 35px 20% 0%;
  height: auto;
}

#features_section .feature_card h5.card-title {
  color: var(--theme-color);
  font-family: "DM Sans";
  font-weight: bold;
  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
}

#features_section .feature_card p.card-text {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  color: #265376;
}

#features_section .feature_mem_svg1 {
  position: absolute;
  width: 5%;
  top: -5%;
  right: 1%;
  opacity: 0.4;
}

#features_section .feature_mem_svg2 {
  position: absolute;
  width: 6%;
  bottom: -10%;
  left: 5%;
  opacity: 0.4;
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {
  #index_header .header_svg_1_text_container_col {
    margin-bottom: 1%;
  }
  #index_header .text_container.header_svg_1_text_container {
    padding-left: 5%;
  }
  #index_header .header_svg_1_img_container {
    padding-right: 0;
  }

  #index_header .header_svg_2_text_container_col {
    justify-content: flex-end !important;
    padding-right: 6%;
    margin-bottom: 14%;
  }
  #index_header .header_svg_2_img_container {
    display: flex;
    justify-content: center;
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) {
}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {
}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {
}

@media (max-width: 500px) {
  .homeFeatureList {
    flex-direction: column;
  }
}
