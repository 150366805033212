.skeleton {
  background-color: #f0f0f0; /* Light gray background color */
  animation: loading 1.5s infinite ease-in-out; /* Apply the animation */
}

@keyframes loading {
  0% {
    opacity: 0.5; /* Start with a semi-transparent color */
  }
  50% {
    opacity: 1; /* Transition to fully opaque */
  }
  100% {
    opacity: 0.5; /* Transition back to semi-transparent */
  }
}
