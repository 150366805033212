.relevant-container{
  position: relative;
}

.relevant-container div.title{
    margin: 15px 0px 10px;
    font-family: "DM Sans";
    color: #1E2864;
    font-weight: bold;
    /* margin-left: 1%; */
    margin-left: 5px;
}

.relevant-container .subtitle{
  font-size: 12px;
  font-weight: 400;
}

.relevant-container .card{
  /* margin: 10px 20px 30px; */
  /* padding: 15px 20px 20px 20px; */
  padding: 0px;
   background: #FFFFFF;
   /* box-shadow: 0px 13px 20px 2px rgb(167 167 167 / 27%); */
   border: 0px;
}
.relevant-container .card h5.card-title{
  font-family: "Poppins";
  font-size: 12px;
  line-height: 16px !important;
  font-weight: 400 !important;
}
.relevant-container .card p.card-text{
  color: #265376;
font-family: "Poppins";
font-size: 11px !important;
line-height: 10px !important;
}

.react-multi-carousel-list{
  position: static !important;
}

.relevant-container .react-multi-carousel-list.container{
  max-width: 100% !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.react-multi-carousel-item  {
  padding: 0px 5px 5px 0px;
}

.react-multiple-carousel__arrow{
  position: absolute;
  right: 10px !important;
  bottom: -30px;
  border-radius: 5px !important;
}

.relevant-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
  right: 96px;
  background: linear-gradient(136.89deg, var(--theme-color) 46.45%, var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;
  min-width: 35px;
  min-height: 35px;
}
.relevant-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
  left: calc(100% + -96px) !important;
    right: 77px !important;
  /* background: #F3ECFF;
  border: 1.94706px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 7.78824px; */
  background: linear-gradient(136.89deg, var(--theme-color) 46.45%, var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;

  min-width: 35px;
  min-height: 35px;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before{
  /* color: #CABBFF; */
  color: #EFE7FF;
}

.relevant-container li.react-multi-carousel-item{
  /* height: 80%; */
  height: inherit;
}

@media (max-width:992px) {
  .relevant-container div.title{
      /* margin: 15px 0px 10px; */
      margin-left: 5px;
      font-family: "DM Sans";
      color: #1E2864;
      font-weight: bold;
      margin-left: 1%;
  }

  .relevant-container .subtitle{
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
.relevant-container  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
    bottom: -25px;
    left: calc(100% + -70px);
    min-width: 35px;
    min-height: 35px;
  }
.relevant-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
    bottom: -25px;
    left: calc(100% + -134px) !important;
    min-width: 35px;
    min-height: 35px;
  }
}

@media (max-width: 768px) {
  .react-multiple-carousel__arrow{
    bottom: -65px;
  }
  .relevant-container div.title{
      /* margin: 25px 0px 25px; */
      margin-left: 5px;
  }
}
