#index_header .text_container{
  width: fit-content;
}


#index_header .text_container h3{
  width: calc(200px + (360 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "DM Sans";
  font-weight: 700;
  color: #1E2864;
  font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1600 - 300)));
}

#index_header .text_container p{
  width: calc(200px + (290 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Poppins";
  font-weight: 500;
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
  line-height: 22px;
  color: #265376;
}

#index_header .text_container a{
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 8px 14px rgba(124, 121, 121, 0.25);
  border-radius: 30px;
  padding: 5px 24px;
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: calc(11px + (14 - 11) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

#index_header .text_container.header_svg_1_text_container a{
  margin-top: 55px;
}

#index_header .text_container.header_svg_2_text_container a{
  margin-top: 25px;
}

#index_header .header_svg_2_text_container_col{
  padding-left: 12%;
}



#index_header .header_svg_1_img_container{
  padding-right: 8%;
  padding-left: 10%;
}

#index_header .header_svg_2_img_container{
}


#index_header .header_mem_svg1{
  position: absolute;
  top: -35%;
  right: -9%;
  width: 176px;
  opacity: 0.4;
}

#index_header .header_mem_svg2{
  position: absolute;
  top: -21%;
  right: 10%;
  width: 10%;
  opacity: 0.4;
}

#index_header .header_mem_svg3{
  position: absolute;
  top: 40%;
  right: 0%;
  width: 5%;
  opacity: 0.4;
}

#index_header .header_mem_svg4{
  position: absolute;
  bottom: -40%;
  right: 10%;
  width: 14%;
  opacity: 0.4;
}

#index_header .header_mem_svg5{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 30%;
  opacity: 0.4;
}

#index_header .header_svg1_sm{
  position: absolute;
  /* top: 27%; */
  /* right: -21%; */
  top: 15%;
  right: -30%;
  width: 50%;
  opacity: 0.4;
}

#index_header .header_svg2_sm{
  position: absolute;
  left: 0%;
  top: -4%;
  width: 25%;
  opacity: 0.4;
}


@media (max-width:500px) {
  #index_header .header_svg2_sm{
    width: 19%;
  }
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {
  #index_header .header_svg_1_text_container_col{
    margin-bottom: 1%;
  }
  #index_header .text_container.header_svg_1_text_container{
    padding-left: 5%;
  }
  #index_header .header_svg_1_img_container{
    padding-right: 0;
  }

  #index_header .text_container.header_svg_1_text_container a{
    margin-top: 10px;
  }

  #index_header .header_svg_2_text_container_col{
    justify-content: flex-end !important;
    padding-right: 6%;
    margin-bottom: 14%;
  }
  #index_header .header_svg_2_img_container{
    display: flex;
    justify-content: center;
  }

  #index_header .header_svg_2_img_container img{
    /* width: 75% !important; */
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) {

}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {

}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}


@media (min-width:576px) {
  #index_header .header_svg_2_img_container img{
    width: calc(100% + (75 - 100) * ((100vw - 300px) / (1600 - 300)));
  }
}

@media (max-width: 576px) {
  #index_header .header_svg_2_img_container img{
    width: 70% !important;
  }
}
