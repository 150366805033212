body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sort-icon {
  font-size: 25px;
  color: grey;
  cursor: pointer;
}

.sort-icon.active {
  color: black;
}

.favourite-table td,
th {
  padding-top: 25px !important;
}

.favourite-table td:first-of-type {
  padding-top: 15px !important;
}

.history-table td,
th {
  padding-top: 25px !important;
}

.history-table td.img {
  padding-top: 0.75rem !important;
}

.favourite-table thead th {
  border-top-width: 0px;
}

.history-table thead th {
  border-top-width: 0px;
}

.post-thumbnail-container {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  width: 105px;
  border-radius: 3;
  overflow: hidden;
  cursor: pointer;
}

.post-thumbnail-container img {
  height: 50px;
  width: 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--theme-color);
}


button.customBtn{
  font-family: "DM Sans";
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 7.20913px 12.616px rgba(124, 121, 121, 0.25);
  border-radius: 27.0342px;
  padding: 10px 50px;
  color: #fff;
  cursor: pointer;
}

button.btn_light,input.btn_light {
  background: #E7DCFF;
  border-radius: 30px;
  padding: 8px 22px;
}
