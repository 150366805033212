#textBody_ckEditor_desc p{
   font-size:14px;
   line-height:20px
}

#single_content_arrow_icon{
  font-size: calc(28px + (45 - 28) * ((100vw - 300px) / (1600 - 300)));
  /* color: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%) !important; */

	background:-moz-linear-gradient(top,var(--theme-color) 0%,var(--theme-color-dark) 100%);
  background: -webkit-linear-gradient(top,var(--theme-color) 0%,var(--theme-color-dark) 100%);
  background: linear-gradient(to bottom,var(--theme-color) 0%,var(--theme-color-dark) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
 -webkit-text-fill-color:transparent;
}

#single_content_arrow_icon_button{
  background: none;
  border: 0px;
  position: absolute;
  z-index: 100;
  left: calc(2px + (20 - 2) * ((100vw - 300px) / (1600 - 300)));
  top: 15px;
}

/* #single_content_leviosa_logo{
  position: fixed;
  right: 27px;
  bottom: 30px;
} */

#single_content_leviosa_logo{
  position: initial;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 20px;
}



@media (min-width:992px) {
  .textBody_border{
    border-left:1px solid #c1afaf;
  }


}

@media (max-width: 600px) {
  #single_content_leviosa_logo{
    position: initial;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 20px;
  }

}
