.tables-box {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 2rem;
  width: 100%;
  margin: 2.5rem 0;
}
.custom-box {
  padding: 2rem 3rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
}
.text-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
}

.info-box {
  display: flex;
  align-items: end;
  gap: 0.5rem;
  justify-content: start;
}
.mood-box {
  padding: 2rem 3rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
}
.mood-box-sections {
  display: flex;
  justify-content: flex-start;
  gap: 10rem;
  align-items: center;
}
.mood-text {
  font-size: large;
}
.heading-margin {
  margin: 3rem 0 0 0;
}
.bothering-section {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.custom-skeleton {
  width: 50%;
  height: 10px;
  background-color: #f0f0f0; /* Light gray background color */
  animation: loading 1.5s infinite ease-in-out; /* Apply the animation */
}

@keyframes loading {
  0% {
    opacity: 0.5; /* Start with a semi-transparent color */
  }
  50% {
    opacity: 1; /* Transition to fully opaque */
  }
  100% {
    opacity: 0.5; /* Transition back to semi-transparent */
  }
}
