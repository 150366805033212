

#nav_section .nav-container{
  margin-top: 40px !important;
}

#nav_section .navbar-nav{
  margin-left: auto;
  margin-right: 20%;
}

#nav_section .navbar-toggler{
  margin-left: auto;
  margin-right: 6%;
  border: 0px;
  z-index: 1000;
}

#nav_section button.navbar-toggler{
  outline: none;
}

#nav_section .navbar-nav a{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: var(--theme-color-light);
  margin: 0px 10px;
  cursor: pointer;
}

#nav_section .navbar-nav a.btn_login{
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 8px 14px rgba(124, 121, 121, 0.25);
  border-radius: 30px;
  color: #fff;
  padding: 8px 25px;
}

#nav_section .navbar-nav a.btn_signup{
  background: #E7DCFF;
  border-radius: 30px;
  padding: 8px 22px;
}


/* #nav_section .logo_svg_cloud_2_container{
  width: fit-content;
  position: absolute;
  right: 0;
  top: 0;
} */

img.logo_svg{
  position: absolute;
  top: 4%;
  left: 9%;
  z-index: 100;
  width: 10%;
  max-height: 120px;
    max-width: 200px;
}

img.custom_logo{
  width: 20%;
}

svg.logo_svg{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 30%;
}

img.logo_svg_cloud_1{
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  z-index: -100;
}

img.logo_svg_cloud_2{
  position: absolute;
  top: 0;
  right: 0;
  width: 5%;
  z-index: -100;
}

.spinner_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #7d5b5b96;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}




/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {


  #nav_section .navbar-nav{
    text-align: center;
    z-index: 100;
    margin-right: 0;
  }
  #nav_section .navbar-nav a.btn_login{
    width: 100px;
    margin: 5px auto;
  }
  #nav_section .navbar-nav a.btn_signup{
    width: 100px;
    margin: 5px auto;
  }

  img.logo_svg_cloud_1{
    left: -9%;
    width: 60%;
  }

  img.logo_svg{
    width: 20%;
  }

  img.custom_logo{
    width: 40%;
  }

  svg.logo_svg{
    width: 60%;
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) and (max-width:992px) {
  #nav_section .navbar-nav{
    margin-left: auto;
    margin-right: 10%;
  }
  #nav_section .navbar-nav a{
    font-size: 11px;
  }

}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {

}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}
