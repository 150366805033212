.pricing_card h5,
h3,
p {
  margin: 0px !important;
}

.popular {
  font-size: 12px;
  background: #ff856a;
  display: inline-block;
  padding: 1px 20px;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
}

.border-gradient {
  /* border: 2px solid; */
  border-image-slice: 1;
  border-width: 1px;
}

.hover-border {
  border: 1px solid #6760d6;
  transition: border-color 0.3s ease;
  margin-bottom: 1rem;
}

.hover-border:hover {
  border-color: transparent;
}
