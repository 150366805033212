.heartBtn{
  position: relative;
    z-index: 200;
    /* right: 9px; */
    padding: 2px;
    background: none;
    border: 0px;
    color: rgb(65 69 69);
    margin-left: 10px !important;
}

.shareBtn{
  position: relative;
    z-index: 200;
    /* right: 40px; */
    padding: 2px;
    background: none;
    border: 0px;
    color: rgb(65 69 69);
}

.badge.badge-pill.custom-badge{
  width: 40px !important;
  position: absolute !important;
	background:#30a11494;
  box-shadow: 0px 0px 5px 1px #69737c;
  /* transform: rotate3d(0, 0, 10, 330deg) !important; */
  top: 5px !important;
  left: 5px !important;
  color: #fff !important;
  font-weight: normal;
}

.starburst {
	display:block;
	width:15px;
	height:20px;
	background:#0d113c3d;
  box-shadow: 0px 0px 5px 1px #69737c;
  border-radius: 3px;
	/* -webkit-transform:rotate(-45deg);
	-moz-transform:rotate(-45deg);
	rotation:-45deg; */
	position: absolute;;
	top:5px;
	right:5px;
	text-align:center;
	text-decoration:none;
	color:#fff;
	font-weight:normal;
  font-size: 14px;
  font-family:Arial, sans-serif;
  z-index: 1000;
}
.starburst span.customStarburstSpan {
	display:block;
	width:25px;
	height:25px;
	background:#fe0;
	/* -webkit-transform:rotate(22.5deg);
	-moz-transform:rotate(22.5deg);
	rotation:22.5deg; */
}

.noticeOverlayHolder{
  display: none;
}

.noticeOverlay{
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.displayNone{
  display: none;
}

.displayBlock{
  display: block;
}

.noticeOverlayText{
  color: #fff !important;
  text-align: center;
  font-size: 13px !important;
}

/* .starburst:hover .tooltiptext {
  visibility: visible;
} */

/* ////////////// */

.featured-container.card{
  margin: 10px 10px 30px 5px;
  /* padding: 15px 20px 20px 20px; */
   background: #FFFFFF;
   box-shadow: 1px 10px 20px 3px rgb(167 167 167 / 27%);
   border: 0px;
   height: 87%;
}




.relevant-container.card{
  /* margin: 10px 20px 30px; */
  margin: 10px 5px 20px 5px;
  /* padding: 15px 20px 20px 20px; */
   background: #FFFFFF;
   /* box-shadow: 0px 13px 20px 2px rgb(167 167 167 / 27%); */
   box-shadow: 1px 3px 15px 2px rgb(167 167 167 / 27%);
   border: 0px;
   height: 87%;
}

.relevant-container.card .card-body{
  /* padding: 1.25rem 1.25rem 0 1.25rem !important; */
  padding: 5px 10px 0px 10px !important;
}

.featured-container.card .card-body{
  padding: 1.25rem 1.25rem 0 1.25rem !important;
}


.relevant-container .card p{
  color: #265376;
font-family: "Poppins";
font-size: 12px;
}

.card .text_combined_icon{
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.card .text_combined_icon img{
  width: 18px;
}

.card .text_combined_icon h5{
  margin: 0px;
}

.card .card-footer{
    padding-top: 2px !important;
    padding-bottom: 10px !important;
    background: none;
    border-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
}


a.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
    cursor: pointer;
}

.adventuresCarousel img.card-img-top{
  object-fit: cover;
}

.featured-container .adventuresCarousel img.card-img-top{
  height: 210px;
}
.relevant-container .adventuresCarousel img.card-img-top{
  height: 100px;
}

@media (min-width:425px) {
  .relevant-container li.react-multi-carousel-item.react-multi-carousel-item--active {
    /* width: calc(224px + (588 - 224) * ((100vw - 300px) / (2560 - 300))) !important; */
    max-width: 250px;
    /* width:250px; */
  }
}

@media (max-width: 768px) {
  .featured-container .adventuresCarousel img.card-img-top {
    height: 170px;
}
}

@media (max-width: 425px) {
  .relevant-container .adventuresCarousel img.card-img-top{
    height: 110px;
  }
}