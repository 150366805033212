/* .player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
} */



@media (min-width: 992px) {
  /* .MuiPaper-root.MuiPaper-elevation3.MuiPaper-rounded .reactPlayer{
    height: 75% !important;
  } */
  .reactPlayer{
    height: 80vh !important;
  }
  .reactPlayer iframe{
    height: 100% !important;
  }
}
