@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sort-icon {
  font-size: 25px;
  color: grey;
  cursor: pointer;
}

.sort-icon.active {
  color: black;
}

.favourite-table td,
th {
  padding-top: 25px !important;
}

.favourite-table td:first-of-type {
  padding-top: 15px !important;
}

.history-table td,
th {
  padding-top: 25px !important;
}

.history-table td.img {
  padding-top: 0.75rem !important;
}

.favourite-table thead th {
  border-top-width: 0px;
}

.history-table thead th {
  border-top-width: 0px;
}

.post-thumbnail-container {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  width: 105px;
  border-radius: 3;
  overflow: hidden;
  cursor: pointer;
}

.post-thumbnail-container img {
  height: 50px;
  width: 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--theme-color);
}


button.customBtn{
  font-family: "DM Sans";
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 7.20913px 12.616px rgba(124, 121, 121, 0.25);
  border-radius: 27.0342px;
  padding: 10px 50px;
  color: #fff;
  cursor: pointer;
}

button.btn_light,input.btn_light {
  background: #E7DCFF;
  border-radius: 30px;
  padding: 8px 22px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* overflow-x: hidden; */
  width: 100%;
  height: 100%;
}

body {
  /* position: relative; */
  background: #ffffff;
  /* overflow-x: hidden; */
  padding: 0px !important;
}

div#root {
  height: 100%; /* remove this line to see div.app is no more 100% height */
  min-height: 100vh;
  /* overflow-x: hidden; */
}

div.App {
  height: 100%;
  min-height: 100vh;
  /* overflow-x: hidden; */
}



#nav_section .nav-container{
  margin-top: 40px !important;
}

#nav_section .navbar-nav{
  margin-left: auto;
  margin-right: 20%;
}

#nav_section .navbar-toggler{
  margin-left: auto;
  margin-right: 6%;
  border: 0px;
  z-index: 1000;
}

#nav_section button.navbar-toggler{
  outline: none;
}

#nav_section .navbar-nav a{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: var(--theme-color-light);
  margin: 0px 10px;
  cursor: pointer;
}

#nav_section .navbar-nav a.btn_login{
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 8px 14px rgba(124, 121, 121, 0.25);
  border-radius: 30px;
  color: #fff;
  padding: 8px 25px;
}

#nav_section .navbar-nav a.btn_signup{
  background: #E7DCFF;
  border-radius: 30px;
  padding: 8px 22px;
}


/* #nav_section .logo_svg_cloud_2_container{
  width: fit-content;
  position: absolute;
  right: 0;
  top: 0;
} */

img.logo_svg{
  position: absolute;
  top: 4%;
  left: 9%;
  z-index: 100;
  width: 10%;
  max-height: 120px;
    max-width: 200px;
}

img.custom_logo{
  width: 20%;
}

svg.logo_svg{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 30%;
}

img.logo_svg_cloud_1{
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  z-index: -100;
}

img.logo_svg_cloud_2{
  position: absolute;
  top: 0;
  right: 0;
  width: 5%;
  z-index: -100;
}

.spinner_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #7d5b5b96;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}




/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {


  #nav_section .navbar-nav{
    text-align: center;
    z-index: 100;
    margin-right: 0;
  }
  #nav_section .navbar-nav a.btn_login{
    width: 100px;
    margin: 5px auto;
  }
  #nav_section .navbar-nav a.btn_signup{
    width: 100px;
    margin: 5px auto;
  }

  img.logo_svg_cloud_1{
    left: -9%;
    width: 60%;
  }

  img.logo_svg{
    width: 20%;
  }

  img.custom_logo{
    width: 40%;
  }

  svg.logo_svg{
    width: 60%;
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) and (max-width:992px) {
  #nav_section .navbar-nav{
    margin-left: auto;
    margin-right: 10%;
  }
  #nav_section .navbar-nav a{
    font-size: 11px;
  }

}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {

}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}

#index_header .text_container{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


#index_header .text_container h3{
  width: calc(200px + (360 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "DM Sans";
  font-weight: 700;
  color: #1E2864;
  font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1600 - 300)));
}

#index_header .text_container p{
  width: calc(200px + (290 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Poppins";
  font-weight: 500;
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
  line-height: 22px;
  color: #265376;
}

#index_header .text_container a{
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 8px 14px rgba(124, 121, 121, 0.25);
  border-radius: 30px;
  padding: 5px 24px;
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: calc(11px + (14 - 11) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

#index_header .text_container.header_svg_1_text_container a{
  margin-top: 55px;
}

#index_header .text_container.header_svg_2_text_container a{
  margin-top: 25px;
}

#index_header .header_svg_2_text_container_col{
  padding-left: 12%;
}



#index_header .header_svg_1_img_container{
  padding-right: 8%;
  padding-left: 10%;
}

#index_header .header_svg_2_img_container{
}


#index_header .header_mem_svg1{
  position: absolute;
  top: -35%;
  right: -9%;
  width: 176px;
  opacity: 0.4;
}

#index_header .header_mem_svg2{
  position: absolute;
  top: -21%;
  right: 10%;
  width: 10%;
  opacity: 0.4;
}

#index_header .header_mem_svg3{
  position: absolute;
  top: 40%;
  right: 0%;
  width: 5%;
  opacity: 0.4;
}

#index_header .header_mem_svg4{
  position: absolute;
  bottom: -40%;
  right: 10%;
  width: 14%;
  opacity: 0.4;
}

#index_header .header_mem_svg5{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 30%;
  opacity: 0.4;
}

#index_header .header_svg1_sm{
  position: absolute;
  /* top: 27%; */
  /* right: -21%; */
  top: 15%;
  right: -30%;
  width: 50%;
  opacity: 0.4;
}

#index_header .header_svg2_sm{
  position: absolute;
  left: 0%;
  top: -4%;
  width: 25%;
  opacity: 0.4;
}


@media (max-width:500px) {
  #index_header .header_svg2_sm{
    width: 19%;
  }
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {
  #index_header .header_svg_1_text_container_col{
    margin-bottom: 1%;
  }
  #index_header .text_container.header_svg_1_text_container{
    padding-left: 5%;
  }
  #index_header .header_svg_1_img_container{
    padding-right: 0;
  }

  #index_header .text_container.header_svg_1_text_container a{
    margin-top: 10px;
  }

  #index_header .header_svg_2_text_container_col{
    justify-content: flex-end !important;
    padding-right: 6%;
    margin-bottom: 14%;
  }
  #index_header .header_svg_2_img_container{
    display: flex;
    justify-content: center;
  }

  #index_header .header_svg_2_img_container img{
    /* width: 75% !important; */
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) {

}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {

}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}


@media (min-width:576px) {
  #index_header .header_svg_2_img_container img{
    width: calc(100% + (75 - 100) * ((100vw - 300px) / (1600 - 300)));
  }
}

@media (max-width: 576px) {
  #index_header .header_svg_2_img_container img{
    width: 70% !important;
  }
}

.searchBtn {
  border: 1px solid var(--theme-color-light);
  color: var(--theme-color-light);
  height: 100%;
  border-radius: 0 0.25rem 0.25rem 0;
}

.searchField {
  border: 1px solid var(--theme-color-light);
}

input.searchField {
  margin: 0px !important;
  border-radius: 0.25rem 0 0 0.25rem;
}

.searchField::placeholder {
  color: var(--theme-color-light);
}

.searchBtn:hover {
  background: linear-gradient(
    136.89deg,
    var(--theme-color-dark) 46.45%,
    var(--theme-color) 69.27%
  );
  color: #fff;
}

.searchBtn:active {
  outline: none;
}

.heartBtnFeature {
  padding: 2px;
  background: none;
  border: 0px;
  color: #dd0000;
}

.atcb-button svg {
  background: none;
  border: 0px;
  fill: #dd0000 !important;
}
.atcb-button {
  border: unset !important;
  box-shadow: unset !important;
  background: unset !important;
  display: flex !important;
  align-items: center !important;
  font-family: unset !important;
}

.atcb-text {
  font-weight: 500 !important;
  color: rgb(33, 37, 41) !important;
  font-size: 16px;
}

.atcb-icon {
  height: 16px !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0px !important;
}

hr {
  border-top: 1px solid #fff0;
}

.text_highlight {
  color: #333131;
  font-weight: 700;
}

.section_title {
  font-family: "DM Sans";
  font-weight: 700;
  color: #1e2864;
}

#features_section .feature_card_container_col {
  margin: 2% 0;
}

#features_section .feature_card.card {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(213, 213, 213, 0.5);
  border-radius: 10px;
  height: 100%;
  border: 0px;
  max-width: 18rem;
}

#features_section .feature_card img {
  padding: 35px 20% 0%;
  height: auto;
}

#features_section .feature_card h5.card-title {
  color: var(--theme-color);
  font-family: "DM Sans";
  font-weight: bold;
  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
}

#features_section .feature_card p.card-text {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  color: #265376;
}

#features_section .feature_mem_svg1 {
  position: absolute;
  width: 5%;
  top: -5%;
  right: 1%;
  opacity: 0.4;
}

#features_section .feature_mem_svg2 {
  position: absolute;
  width: 6%;
  bottom: -10%;
  left: 5%;
  opacity: 0.4;
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {
  #index_header .header_svg_1_text_container_col {
    margin-bottom: 1%;
  }
  #index_header .text_container.header_svg_1_text_container {
    padding-left: 5%;
  }
  #index_header .header_svg_1_img_container {
    padding-right: 0;
  }

  #index_header .header_svg_2_text_container_col {
    justify-content: flex-end !important;
    padding-right: 6%;
    margin-bottom: 14%;
  }
  #index_header .header_svg_2_img_container {
    display: flex;
    justify-content: center;
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) {
}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {
}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {
}

@media (max-width: 500px) {
  .homeFeatureList {
    flex-direction: column;
  }
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}

.mood-check-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 35px;
  gap: 35px;
  padding: 40px 50px;
  margin-bottom: 20px;
}

.option-container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.option-group {
  display: flex;
  align-items: center;
  grid-gap: 30px;
  grid-gap: 17px;
  gap: 17px;
  flex-wrap: wrap;
  justify-content: center;
}

.option-group.with-counts {
  grid-gap: 20px;
  gap: 20px;
}

.option-group.small-icons {
  grid-gap: 5px;
  gap: 5px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  border: 1px solidvar(--theme-color);
  padding: 2px 8px;
  border-radius: 10px;
}

.option span {
  font-size: 12px;
}

.option.active {
  background: var(--theme-color);
  color: white;
}
.option-with-image .count {
  /* background:var(--theme-color); */
  color: var(--theme-color);
  /* width: 30px;
    height: 30px;
    border-radius: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-with-image {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 3px;
}

.option-with-image.active {
  border-bottom: 3px solid var(--theme-color);
}

.option-with-image img {
  width: 50px;
}

.option-with-image.small img {
  width: 25px;
}

.option-with-image span {
  font-size: 12px;
  color: unset !important;
}

.option-with-image .color-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.option-with-image.small .color-container {
  width: 25px;
  height: 25px;
}

@media (max-width: 768px) {
  .mood-check-container {
    padding: 15px;
  }
}

.Cdropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .Cdropdown {
    position: relative;
    display: inline-block;
  }
  
  .Cdropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9;
  }
  
  .Cdropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .Cdropdown-content a:hover {background-color: #f1f1f1}
  
  .Cdropdown:hover .Cdropdown-content {
    display: block;
  }
  
  .Cdropdown:hover .Cdropbtn {
    background-color: #3e8e41;
  }
.mood-check-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 35px;
  gap: 35px;
  padding: 40px 50px;
  margin-bottom: 20px;
}

.option-container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.option-group {
  display: flex;
  align-items: center;
  grid-gap: 30px;
  grid-gap: 17px;
  gap: 17px;
  flex-wrap: wrap;
  justify-content: center;
}

.option-group.with-counts {
  grid-gap: 20px;
  gap: 20px;
}

.option-group.small-icons {
  grid-gap: 5px;
  gap: 5px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  border: 1px solidvar(--theme-color);
  padding: 2px 8px;
  border-radius: 10px;
}

.option span {
  font-size: 12px;
}

.option.active {
  background: var(--theme-color);
  color: white;
}
.option-with-image .count {
  /* background:var(--theme-color); */
  color: var(--theme-color);
  /* width: 30px;
    height: 30px;
    border-radius: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-with-image {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 3px;
}

.option-with-image.active {
  border-bottom: 3px solid var(--theme-color);
}

.option-with-image img {
  width: 50px;
}

.option-with-image.small img {
  width: 25px;
}

.option-with-image span {
  font-size: 12px;
  color: unset !important;
}

.option-with-image .color-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.option-with-image.small .color-container {
  width: 25px;
  height: 25px;
}

@media (max-width: 768px) {
  .mood-check-container {
    padding: 15px;
  }
}

footer{
  /* background-image: url("./assets/index/footer_svg_background.svg"); */
  height: 80vh;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  position: relative;
}

footer .footer_items_container{
  width: 65%;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
}

footer .footer_items_container .footer_items_top_container{
  margin-bottom: 5%;
}

footer .footer_items_container .row div.col-4{
  display: flex;
  justify-content: center;
}

footer .footer_items_container h5{
  color: #fff;
}

footer .footer_items_container p{
  color: #fff;
}

footer .footer_items_container h5{
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  font-weight: 500;
}

footer .footer_items_container p{
  font-family: "Poppins";
  font-size: 16px !important;
  font-weight: 400;
  margin-bottom: 13px !important;
}

footer .footer_items_container h5.title{
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 25px;
}

footer .footer_items_container .logo img{
  width: 60%;
}

footer .footer_items_container .social_icon{
  margin-bottom: 20px;
  text-align: center;
}
footer .footer_items_container .social_icon a{
  margin: 0px 5px;
}
footer .footer_items_container .social_icon img{
  width: 38px;
}

footer .footer_items_container .terms{
  font-family: "Poppins";
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5%;
}

footer .footer_items_container .terms a{
  color: #fff;
}

footer .footer_items_container .copy_rights p{
  font-family: "Poppins";
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 0px !important;
}

/* memphis 1 */
footer .footer_left_svg1{
  position: absolute;
  width: 7%;
  left: 2%;
  top: 20%;
  opacity: 0.4;
}

footer .footer_left_svg2{
  position: absolute;
  top: 58%;
  left: 0%;
  width: 10%;
  opacity: 0.4;
}

footer .footer_left_svg3{
  position: absolute;
  bottom: 0%;
  left: 3%;
  width: 10%;
  opacity: 0.4;
}

footer .footer_right_svg1{
  position: absolute;
  right: 11%;
  top: 10%;
  width: 6%;
  opacity: 0.4;
}

footer .footer_right_svg2{
  position: absolute;
  top: 33%;
  right: -2%;
  width: 12%;
  opacity: 0.4;
}

footer .footer_right_svg3{
  position: absolute;
  bottom: 0%;
  right: 2%;
  width: 15%;
  opacity: 0.4;
}

#mobile_view_footer .footer_items_container .logo img{
  width: 50%;
}

#mobile_view_footer .footer_items_container{
  width: 75%;
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {


  #pricing_section .container{
    width: 100% !important;
  }

  footer .footer_items_container .logo img{
    width: 90%;
  }

  #mobile_view_footer .footer_items_container .logo img{
    width: 45%;
  }

  #mobile_view_footer .footer_items_container{
    bottom: 17%;
  }

  /* memphis sm right */
  #mobile_view_footer .footer_left_svg1_sm{
    position: absolute;
    bottom: 0%;
    left: 10%;
    width: 14%;
  }
  #mobile_view_footer .footer_right_svg2_sm{
    position: absolute;
    top: 44%;
    right: 11%;
    width: 16%;
  }
  #mobile_view_footer .footer_right_svg2_sm{
    position: absolute;
    bottom: 50%%;
    right: 15%;
    width: 12%;
  }

  footer .footer_items_container .social_icon img{
    width: 25px;
  }


  footer .footer_items_container h5{
    font-size: 12px;
  }

  footer .footer_items_container p{
    font-size: 12px;
  }

  footer .footer_items_container h5.title{
    font-size: 12px;
  }

}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) and (max-width: 992px) {
  #pricing_section .container{
    width: 90% !important;
  }

  footer .footer_items_container .social_icon img{
    width: 30px;
  }

  footer .footer_items_container .logo img{
    width: 80%;
  }


  footer .footer_items_container h5{
    font-size: 14px;
  }

  footer .footer_items_container p{
    font-size: 14px;
  }

  footer .footer_items_container h5.title{
    font-size: 14px;
  }


  /* memphis 1 */
  footer .footer_left_svg1{
    position: absolute;
    width: 7%;
    left: 2%;
    top: 12%;
  }

  footer .footer_left_svg2{
    position: absolute;
    top: 39%;
    left: 0%;
    width: 14%;
  }

  footer .footer_left_svg3{
    position: absolute;
    bottom: 4%;
    left: 3%;
    width: 18%;
  }

  footer .footer_right_svg1{
    position: absolute;
    right: 11%;
    top: 10%;
    width: 10%;
  }

  footer .footer_right_svg2{
    position: absolute;
    top: 33%;
    right: -2%;
    width: 16%;
  }

  footer .footer_right_svg3{
    position: absolute;
    bottom: 0%;
    right: 2%;
    width: 21%;
  }




}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) and (max-width: 1200px) {

}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}

@media (max-width:500px) {
  footer .footer_items_container .social_icon img{
    width: 18px;
  }
}

@media (max-width:350px) {
  footer .footer_items_container .social_icon img{
    width: 14px;
  }

}

div#pricingAlertId .MuiAlert-icon .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit{
  color: #fff;
}

.section_title{
    font-family: "DM Sans";
    font-weight: 700;
    color: #1E2864;
}




#pricing_section .container{
  width: 60% !important;
}

#pricing_section .pricing_card_container_col{
  /* margin: 1% -0.5%; */
  margin: 1% 0;
}

#pricing_section .pricing_card.card{
  margin: auto;
  background: #FFFFFF;
  /* border: 2px solid #DCB0FF; */
  box-sizing: border-box;
  box-shadow: 0px 4.44717px 40.0245px rgba(0, 0, 0, 0.1);
  height: 100%;
}

#pricing_section .pricing_card h5.card-title{
  font-weight: 600;
  font-size: 13px;
}

#pricing_section .pricing_card h3{
  font-family: "DM Sans";
  font-size: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 5px 0px !important;
}
#pricing_section .pricing_card .currency{
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 26px;
  margin-right: 10px;
}

#pricing_section .pricing_card .period{
  font-family: "DM Sans";
  font-weight: 300;
  font-size: 13px;
}

#pricing_section .pricing_card p.card-text{
  color: #5B6B82;
  font-family: "DM Sans";
  font-style: normal;
  font-size: 13px;
}

#pricing_section .pricing_card .card-footer{
  background-color: #fff;
  border-top: 0px;
  padding-bottom: 10%;
  text-align: center;
}

#pricing_section .pricing_card .card-footer a{
  border: 1.29234px solidvar(--theme-color);
  box-sizing: border-box;
  border-radius: 22.17px;
  color: #5B6B82;
  text-decoration: none;
  padding: 5% 25%;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 14px;
}

#pricing_section .pricing_card .card-footer a:hover{
  background: linear-gradient(136.89deg, #446DD6 46.45%,var(--theme-color) 69.27%);
  border-radius: 20.17px;
  color: #fff;
}

#pricing_section .price_svg_cloud{
  position: absolute;
  left: -19%;
  top: -22%;
  width: 40%;
  z-index: -100;
}



#pricing_section .price_svg_people_text_container h3{
  width: calc(120px + (360 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "DM Sans";
  font-weight: 700;
  color: #1E2864;
  font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1600 - 300)));
}

#pricing_section .price_svg_people_text_container p{
  width: calc(200px + (290 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Poppins";
  font-weight: 500;
  font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
  line-height: 22px;
  color: #265376;
}

#pricing_section .price_svg_people_img_container{
  padding-right: 15%;
  padding-left: 10%;
}

#pricing_section .price_svg_people_sm{
  position: absolute;
top: 0%;
right: 8%;
width: 20%;
opacity: 0.4;
}

#pricing_section .pricing_simple_alerts{
   /* width:50%; */
   /* margin-left:auto; */
   margin: auto;
   width: 100%;
}


/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {


  #pricing_section .container{
    width: 100% !important;
  }

  #pricing_section .price_svg_people_text_container_col{
    margin-bottom: 1%;
  }
  #pricing_section .text_container.price_svg_people_text_container{
    padding-left: 5%;
  }
  #pricing_section .price_svg_people_text_container{
    padding-right: 0;
  }

  #pricing_section .pricing_simple_alerts{
     width:100%;
     margin: auto;
  }


}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) and (max-width: 992px) {
  #pricing_section .container{
    width: 90% !important;
  }
}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) and (max-width: 1200px) {
  #pricing_section .container{
    width: 70% !important;
  }
}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}

@media (max-width:500px) {
  #pricing_section .pricing_card h3{
    font-size: 28px;
  }

  #pricing_section .pricing_card .currency{
    font-size: 12px
  }

  #pricing_section .pricing_card .period{
    font-size: 10px;
  }

  #pricing_section .pricing_card .card-footer a{
    padding: 3% 15%;
    font-size: 10px;
  }
}

.pricing_card h5,
h3,
p {
  margin: 0px !important;
}

.popular {
  font-size: 12px;
  background: #ff856a;
  display: inline-block;
  padding: 1px 20px;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
}

.border-gradient {
  /* border: 2px solid; */
  border-image-slice: 1;
  border-width: 1px;
}

.hover-border {
  border: 1px solid #6760d6;
  transition: border-color 0.3s ease;
  margin-bottom: 1rem;
}

.hover-border:hover {
  border-color: transparent;
}

.modal-top{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
  background:var(--theme-color);
  border-radius: 6px 6px 0px 0px;
}

.modal-header{
  border-bottom: 0px !important;
}

.modal-content{
  background-color: var(--theme-color-light2);
  border: 0px;

}

.modal-body{
  padding: 0px 100px;
}

.modal-header button{
  background: none !important;
}
.modal-title button span{
  background-color: #D6BFF5;
}

.modal-dialog{
  max-width: 700px;
}

@media (max-width: 768px) {
  .modal-body{
    padding: 0px;
  }
}

.loading{
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.loading div{
  display: block;
  margin: auto;
}

.modal{
  font-family: "DM Sans";
}

.modal-content h2,label{
  color: #1E2864;
  font-weight: 600;
}

.modal-content p{
  color: #3D0CA4;
}

.modal-content a{
  color: #9261EB;
  text-decoration: none;
}

.modal-content button.customBtn{
  font-family: "DM Sans";
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 7.20913px 12.616px rgba(124, 121, 121, 0.25);
  border-radius: 27.0342px;
  padding: 10px 50px;
  color: #fff;
  cursor: pointer;
}

.error{
  color: #ff0000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
}

.modal-top{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
  background:var(--theme-color);
  border-radius: 6px 6px 0px 0px;
}

.modal-header{
  border-bottom: 0px !important;
}

.modal-content{
  background-color: var(--theme-color-light2);
  border: 0px;

}

.modal-body{
  padding: 0px 100px;
}

.modal-header button{
  background: none !important;
}

.modal-title button span{
  background-color: #D6BFF5;
}

.modal-dialog{
  max-width: 700px;
}

.spinner_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #7d5b5b96;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


@media (max-width: 768px) {
  .modal-body{
    padding: 0px;
  }
}

/* #root {
  display: flex;
  align-items: center;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
} */

#checkout-button-alert button{
  padding: 0px !important;
  border-radius: 0px !important;
  background: none !important;
  box-shadow: none !important;
}
#stripe form {
  width: 30vw;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
#stripe input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#stripe .result-message {
  line-height: 22px;
  font-size: 16px;
}
#stripe .result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
#stripe .hidden {
  display: none;
}
#stripe #card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
#stripe #card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#stripe #payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
#stripe button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
#stripe button:hover {
  filter: contrast(115%);
}
#stripe button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
#stripe .spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
#stripe .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}
#stripe .spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
#stripe .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}
#stripe .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  #stripe form {
    width: 80vw;
  }
}

#backToSignUpText{
  margin-left: 8px !important
}

.modal{
  font-family: "DM Sans";
}
.modal-dialog .modal-dialog-centered{
  position: relative;
}

.modal-content h2,label{
  color: #1E2864;
  font-weight: 600;
}

.modal-content p{
  color: #3D0CA4;
}

.modal-content a{
  color: #9261EB;
  text-decoration: none;
}

.modal-content button.customBtn{
  font-family: "DM Sans";
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 7.20913px 12.616px rgba(124, 121, 121, 0.25);
  border-radius: 27.0342px;
  padding: 10px 50px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: 0px;
}

.spinner_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #7d5b5b96;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.error{
  color: #ff0000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
}
.disabled-btn{
  opacity: 20% !important;
}

@media (max-width: 768px) {
  .modal-content button{
    padding: 5px 20px;
  }
}

.modal-top{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10px;
    background:var(--theme-color);
    border-radius: 6px 6px 0px 0px;
  }
  
  .modal-header{
    border-bottom: 0px !important;
  }
  
  .modal-content{
    background-color: var(--theme-color-light2);
    border: 0px;
  
  }
  
  .modal-body{
    padding: 0px 100px;
  }
  
  .modal-header button{
    background: none !important;
  }
  .modal-title button span{
    background-color: #D6BFF5;
  }
  
  .modal-dialog{
    max-width: 700px;
  }
  
  @media (max-width: 768px) {
    .modal-body{
      padding: 0px;
    }
  }
  
.loading{
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.loading div{
  display: block;
  margin: auto;
}

.modal{
  font-family: "DM Sans";
}

.modal-content h2,label{
  color: #1E2864;
  font-weight: 600;
}

.modal-content p{
  color: #3D0CA4;
}

.modal-content a{
  color: #9261EB;
  text-decoration: none;
}

.modal-content button.customBtn{
  font-family: "DM Sans";
  background: linear-gradient(136.89deg, var(--theme-color) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 0px 7.20913px 12.616px rgba(124, 121, 121, 0.25);
  border-radius: 27.0342px;
  padding: 10px 50px;
  color: #fff;
  cursor: pointer;
  border: 0.3px solid grey;
}

.error{
  color: #ff0000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
}

.modal-top{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10px;
    background:var(--theme-color);
    border-radius: 6px 6px 0px 0px;
  }
  
  .modal-header{
    border-bottom: 0px !important;
  }
  
  .modal-content{
    background-color: var(--theme-color-light2);
    border: 0px;
  
  }
  
  .modal-body{
    padding: 0px 100px;
  }
  
  .modal-header button{
    background: none !important;
  }
  .modal-title button span{
    background-color: #D6BFF5;
  }
  
  .modal-dialog{
    max-width: 700px;
  }
  
  @media (max-width: 768px) {
    .modal-body{
      padding: 0px;
    }
  }
  
#home_header .header_top_container h1 {
  font-family: "DM Sans";
  font-weight: 600;
  color: #231b51;
}

#home_header .header_middle_container {
  background: #ffffff;
  border: 1px solid var(--theme-color-light);
  box-sizing: border-box;
  box-shadow: 2px 4px 7px 3px #efe6ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1.5% 1%;
}

#home_header .header_middle_container img.profile_icon_svg {
  width: 75px;
}

#home_header .header_middle_container p {
  margin-bottom: 0;
}

#home_header .text_container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#home_header .header_top_container h1 {
  font-size: calc(34px + (56 - 34) * ((100vw - 300px) / (1600 - 300)));
}

#home_header .text_container h3 {
  width: 500px;
  font-family: "DM Sans";
  font-weight: 700;
  color: #1e2864;
  font-size: calc(20px + (28 - 20) * ((100vw - 300px) / (1600 - 300)));
}

#home_header .text_container p {
  width: calc(200px + (290 - 200) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #265376;
}

#home_header .text_container a {
  background: linear-gradient(
    136.89deg,
    var(--theme-color-dark) 46.45%,
    var(--theme-color) 69.27%
  );
  box-shadow: 0px 8px 14px rgba(124, 121, 121, 0.25);
  border-radius: 30px;
  padding: 8px 24px;
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: calc(8px + (14 - 8) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

#home_header .text_container.header_bottom_text_container a {
  margin-top: 20px;
}

#home_header .header_svg1 {
  position: absolute;
  width: 5%;
  top: -138%;
  left: 14%;
  opacity: 0.4;
}

#home_header .header_svg2 {
  position: absolute;
  width: 7%;
  right: -2%;
  top: -111%;
  opacity: 0.4;
}

#home_header .header_svg3 {
  position: absolute;
  right: 0%;
  top: 30%;
  opacity: 0.4;
}

#home_header .header_svg4 {
  position: absolute;
  width: 11%;
  right: 0%;
  top: 23%;
  opacity: 0.4;
}

#home_header .header_svg5 {
  position: absolute;
  width: 5%;
  bottom: -28%;
  left: 0%;
  opacity: 0.4;
}

#home_header .header_svg1_sm {
  position: absolute;
  right: 0%;
  top: -35%;
  width: 34%;
  opacity: 0.4;
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {
  #home_header .container {
    width: 95%;
  }
  #home_header .header_bottom_text_container_col {
    margin-bottom: 1%;
  }
  #home_header .text_container.header_bottom_text_container {
    /* padding-left: 20%; */
    justify-content: flex-end;
  }

  #features_section .feature_card img {
    height: auto;
  }

  #features_section .feature_card p.card-text {
    font-size: 10px;
  }

  #home_header .text_container h3 {
    width: auto;
  }

  #home_header .profile_holder h5 {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  }

  #home_header .profile_holder p {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  }

  #home_header .header_middle_container img.profile_icon_svg {
    width: calc(40px + (50 - 40) * ((100vw - 300px) / (1600 - 300)));
  }

  #home_header .header_svg4 {
  }
}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) {
}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) {
}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {
}

@media (max-width: 400px) {
  #home_header .header_svg2_sm {
    display: block !important;
    position: absolute;
    left: 0%;
    top: 9%;
    width: 23%;
    opacity: 0.4;
  }

  #home_header .header_bottom_text_container_col {
    justify-content: flex-end !important;
  }

  #home_header .text_container h3 {
    width: calc(150px + (200 - 150) * ((100vw - 300px) / (1600 - 300)));
  }
}

#smallFooter .sm_footer_svg1_sm{
  position: absolute;
right: 0;
top: -50%;
width: 18%;
overflow: hidden;
opacity: 40%;
}

footer#smallFooter{
  margin-top: 5%;
  display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

footer#smallFooter{
  color: #542B96;
  font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
}

@media (max-width: 768px) {
  footer#smallFooter{
    margin-top: 15%;
  }
}

.gurus-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 80px;
  margin-top: 20px;
}

.gurus-section h3 {
  margin: 0px 15px !important;
  color: #1E2864;
  text-align: center;
}

.gurus-list {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  width: 600px;
}

.gurus-list.web-view {
 flex-wrap: nowrap;
}

.gurus-list.web-view .swiper-slide {
  width: 150px !important;
}



.gurus-list .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 0px;
}
.gurus-list .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 0px;
}

.gurus-list .small {
  max-width: 500px;
}

.each-guru {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.each-guru span {
  max-width: 150px;
  text-align: center;
}

.each-guru:hover span {
  text-decoration: underline;
}

.each-guru .img-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 6px 0px rgb(213 213 213);
}

.each-guru .img-container img {
  display: flex;
  width: 100%;
  height: auto;
}


@media (max-width: 768px) {
  .gurus-list {
    width: calc(100% - 70px);
  }
}
.each-guru {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  /* min-width: 100%; */
}

.each-guru span {
  max-width: 150px;
  text-align: center;
}

.each-guru:hover span {
  text-decoration: underline;
}

.each-guru .img-container {
  background-position:center;
  background-repeat: no-repeat;
  background-size:cover;
}

.each-guru.small .img-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 9px 6px rgb(213 213 213);
  overflow: hidden;

}

.each-guru.large .img-container {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 9px 6px rgb(213 213 213);
  overflow: hidden;
}

.each-guru .img-container img {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.statisticsContainer {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 30px;
    height: calc(100% - 35px);
}

.dashboardContainer * {
    color: #555555 !important
}

.statisticsContainer h1,h2,h3,h4,h5,h6 {
    color: #1E2864;
}
.statisticCard {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgb(213 213 213 / 50%);
    border-radius: 10px;
    height: 100%;
    border: 0px;
    flex: 1 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px 25px;
    grid-gap: 20px;
    gap: 20px;
}

.statisticCard .count-container {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;
}
.statisticCard .count-container >h5:nth-child(2) {

    color: #6F4CDD;
} 
.statisticCard  h2  {
    color: #6F4CDD;
}
.countText {
    font-size: 12px;
}

.category-counts {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    width: 100%;
    flex: 1 1;
}

.category-count-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.category-count-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.category-count-container > h6:first-of-type {
    font-weight: 400;
}

.statistics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.time-selector {
}

.nav-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: var(--theme-color-light);
    margin: 0px 10px;
    cursor: pointer;
}
.nav-link:hover {
    color: var(--theme-color-light);
    text-decoration: underline;
}

@media (max-width: 768px) {
    .statisticsContainer {
        grid-gap: 20px;
        gap: 20px;
        padding: 0px 10px;
        height: unset;
    }

    .statisticCard {
        flex: unset;
    }
}
.heartBtn{
  position: relative;
    z-index: 200;
    /* right: 9px; */
    padding: 2px;
    background: none;
    border: 0px;
    color: rgb(65 69 69);
    margin-left: 10px !important;
}

.shareBtn{
  position: relative;
    z-index: 200;
    /* right: 40px; */
    padding: 2px;
    background: none;
    border: 0px;
    color: rgb(65 69 69);
    margin-left: 10px !important;
}

.badge.badge-pill.custom-badge{
  width: 40px !important;
  position: absolute !important;
	background:#30a11494;
  box-shadow: 0px 0px 5px 1px #69737c;
  /* transform: rotate3d(0, 0, 10, 330deg) !important; */
  top: 5px !important;
  left: 5px !important;
  color: #fff !important;
  font-weight: normal;
}

.starburst {
	display:block;
	width:15px;
	height:20px;
	background:#0d113c3d;
  box-shadow: 0px 0px 5px 1px #69737c;
  border-radius: 3px;
	/* -webkit-transform:rotate(-45deg);
	-moz-transform:rotate(-45deg);
	rotation:-45deg; */
	position: absolute;;
	top:5px;
	right:5px;
	text-align:center;
	text-decoration:none;
	color:#fff;
	font-weight:normal;
  font-size: 14px;
  font-family:Arial, sans-serif;
  z-index: 1000;
}
.starburst span.customStarburstSpan {
	display:block;
	width:25px;
	height:25px;
	background:#fe0;
	/* -webkit-transform:rotate(22.5deg);
	-moz-transform:rotate(22.5deg);
	rotation:22.5deg; */
}

.noticeOverlayHolder{
  display: none;
}

.noticeOverlay{
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.displayNone{
  display: none;
}

.displayBlock{
  display: block;
}

.noticeOverlayText{
  color: #fff !important;
  text-align: center;
  font-size: 13px !important;
}

/* .starburst:hover .tooltiptext {
  visibility: visible;
} */

/* ////////////// */

.featured-container.card{
  margin: 10px 10px 30px 5px;
  padding: 15px 20px 20px 20px;
   background: #FFFFFF;
   box-shadow: 1px 10px 20px 3px rgb(167 167 167 / 27%);
   border: 0px;
   height: 87%;
}




.relevant-container.card{
  /* margin: 10px 20px 30px; */
  margin: 10px 5px 20px 5px;
  padding: 15px 20px 20px 20px;
   background: #FFFFFF;
   /* box-shadow: 0px 13px 20px 2px rgb(167 167 167 / 27%); */
   box-shadow: 1px 3px 15px 2px rgb(167 167 167 / 27%);
   border: 0px;
   height: 87%;
}

.relevant-container.card .card-body{
  /* padding: 1.25rem 1.25rem 0 1.25rem !important; */
  padding: 5px 10px 0px 10px !important;
}

.featured-container.card .card-body{
  padding: 1.25rem 1.25rem 0 1.25rem !important;
}


.relevant-container .card p{
  color: #265376;
font-family: "Poppins";
font-size: 12px;
}

.card .text_combined_icon{
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.card .text_combined_icon img{
  width: 18px;
}

.card .text_combined_icon h5{
  margin: 0px;
}

.card .card-footer{
    padding-top: 2px !important;
    padding-bottom: 10px !important;
    background: none;
    border-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
}


a.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
    cursor: pointer;
}

.adventuresCarousel img.card-img-top{
  object-fit: cover;
}

.featured-container .adventuresCarousel img.card-img-top{
  height: 210px;
}
.relevant-container .adventuresCarousel img.card-img-top{
  height: 100px;
}

@media (min-width:425px) {
  .relevant-container li.react-multi-carousel-item.react-multi-carousel-item--active {
    /* width: calc(224px + (588 - 224) * ((100vw - 300px) / (2560 - 300))) !important; */
    max-width: 250px;
    /* width:250px; */
  }
}

@media (max-width: 768px) {
  .featured-container .adventuresCarousel img.card-img-top {
    height: 170px;
}
}

@media (max-width: 425px) {
  .relevant-container .adventuresCarousel img.card-img-top{
    height: 110px;
  }
}
.featured-container{
  position: relative;
}

.featured-container div.title{
    margin: 15px 0px 10px;
    font-family: "DM Sans";
    color: #1E2864;
    font-weight: bold;
    /* margin-left: 1%; */
    margin-left: 5px;
}

.featured-container .subtitle{
  font-size: 12px;
  font-weight: 400;
}

.featured-container .card{
  /* margin: 10px 20px 30px; */
  /* padding: 15px 20px 20px 20px; */
  padding: 0px;
   background: #FFFFFF;
   /* box-shadow: 0px 13px 20px 2px rgb(167 167 167 / 27%); */
   border: 0px;
}

.featured-container .card h5.card-title{
  font-family: "Poppins";
  font-size: 12px;
}
.featured-container .card p.card-text{
  color: #265376;
font-family: "Poppins";
font-size: 12px;
}

.react-multi-carousel-list{
  position: static !important;
}

.react-multi-carousel-list.container{
  max-width: 100% !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.react-multi-carousel-item  {
  padding: 0px 15px 5px 0px;
}

.react-multiple-carousel__arrow{
  position: absolute;
  right: 0px;
  bottom: -20px;
  border-radius: 5px !important;
}

.featured-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
  right: 0px;
  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;
  min-width: 35px;
  min-height: 35px;
}
.featured-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
  left: calc(100% + -80px);
  /* background: #F3ECFF;
  border: 1.94706px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 7.78824px; */

  background: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;
  
  min-width: 35px;
  min-height: 35px;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before{
  /* color: #CABBFF; */
  color: #EFE7FF;
}

.featured-container li.react-multi-carousel-item{
  /* height: 90%; */
  height: inherit;
}

.featured-container .mem_lg_top1_svg{
  position: absolute;
top: -73%;
left: 25%;
width: 14%;
opacity: 0.4;
}

.featured-container .mem_sm_top1_svg{
  position: absolute;
top: -10%;
right: 11%;
width: 21%;
opacity: 0.4;
}

.featured-container .mem_sm_top2_svg{
  position: absolute;
left: -3%;
bottom: -11%;
width: 10%;
opacity: 0.4;
}


@media (max-width:992px) {
  .featured-container div.title{
    /* margin: 15px 0px 10px; */
      margin: 5px;
      font-family: "DM Sans";
      color: #1E2864;
      font-weight: bold;
      margin-left: 1%;
  }

  .featured-container .subtitle{
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
.featured-container  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
    bottom: -25px;
    left: calc(100% + -70px);
    min-width: 35px;
    min-height: 35px;
  }
.featured-container  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
    bottom: -25px;
    left: calc(100% + -120px);
    min-width: 35px;
    min-height: 35px;
  }
}

@media (max-width: 768px) {
  .react-multiple-carousel__arrow{
    bottom: -65px;
  }
  .featured-container div.title{
    /* margin: 25px 0px 25px; */
      margin: 5px;
  }
}

.relevant-container{
  position: relative;
}

.relevant-container div.title{
    margin: 15px 0px 10px;
    font-family: "DM Sans";
    color: #1E2864;
    font-weight: bold;
    /* margin-left: 1%; */
    margin-left: 5px;
}

.relevant-container .subtitle{
  font-size: 12px;
  font-weight: 400;
}

.relevant-container .card{
  /* margin: 10px 20px 30px; */
  /* padding: 15px 20px 20px 20px; */
  padding: 0px;
   background: #FFFFFF;
   /* box-shadow: 0px 13px 20px 2px rgb(167 167 167 / 27%); */
   border: 0px;
}
.relevant-container .card h5.card-title{
  font-family: "Poppins";
  font-size: 12px;
  line-height: 16px !important;
  font-weight: 400 !important;
}
.relevant-container .card p.card-text{
  color: #265376;
font-family: "Poppins";
font-size: 11px !important;
line-height: 10px !important;
}

.react-multi-carousel-list{
  position: static !important;
}

.relevant-container .react-multi-carousel-list.container{
  max-width: 100% !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.react-multi-carousel-item  {
  padding: 0px 5px 5px 0px;
}

.react-multiple-carousel__arrow{
  position: absolute;
  right: 10px !important;
  bottom: -30px;
  border-radius: 5px !important;
}

.relevant-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
  right: 96px;
  background: linear-gradient(136.89deg, var(--theme-color) 46.45%, var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;
  min-width: 35px;
  min-height: 35px;
}
.relevant-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
  left: calc(100% + -96px) !important;
    right: 77px !important;
  /* background: #F3ECFF;
  border: 1.94706px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 7.78824px; */
  background: linear-gradient(136.89deg, var(--theme-color) 46.45%, var(--theme-color) 69.27%);
  box-shadow: 3px 13px 14px rgba(124, 121, 121, 0.25);
  border-radius: 10px;
  color: #EFE7FF;

  min-width: 35px;
  min-height: 35px;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before{
  /* color: #CABBFF; */
  color: #EFE7FF;
}

.relevant-container li.react-multi-carousel-item{
  /* height: 80%; */
  height: inherit;
}

@media (max-width:992px) {
  .relevant-container div.title{
      /* margin: 15px 0px 10px; */
      margin-left: 5px;
      font-family: "DM Sans";
      color: #1E2864;
      font-weight: bold;
      margin-left: 1%;
  }

  .relevant-container .subtitle{
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
.relevant-container  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
    bottom: -25px;
    left: calc(100% + -70px);
    min-width: 35px;
    min-height: 35px;
  }
.relevant-container button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
    bottom: -25px;
    left: calc(100% + -134px) !important;
    min-width: 35px;
    min-height: 35px;
  }
}

@media (max-width: 768px) {
  .react-multiple-carousel__arrow{
    bottom: -65px;
  }
  .relevant-container div.title{
      /* margin: 25px 0px 25px; */
      margin-left: 5px;
  }
}

#textBody_ckEditor_desc p{
   font-size:14px;
   line-height:20px
}

#single_content_arrow_icon{
  font-size: calc(28px + (45 - 28) * ((100vw - 300px) / (1600 - 300)));
  /* color: linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%) !important; */
  background: linear-gradient(to bottom,var(--theme-color) 0%,var(--theme-color-dark) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
 -webkit-text-fill-color:transparent;
}

#single_content_arrow_icon_button{
  background: none;
  border: 0px;
  position: absolute;
  z-index: 100;
  left: calc(2px + (20 - 2) * ((100vw - 300px) / (1600 - 300)));
  top: 15px;
}

/* #single_content_leviosa_logo{
  position: fixed;
  right: 27px;
  bottom: 30px;
} */

#single_content_leviosa_logo{
  position: initial;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 20px;
}



@media (min-width:992px) {
  .textBody_border{
    border-left:1px solid #c1afaf;
  }


}

@media (max-width: 600px) {
  #single_content_leviosa_logo{
    position: initial;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 20px;
  }

}

/* .player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
} */



@media (min-width: 992px) {
  /* .MuiPaper-root.MuiPaper-elevation3.MuiPaper-rounded .reactPlayer{
    height: 75% !important;
  } */
  .reactPlayer{
    height: 80vh !important;
  }
  .reactPlayer iframe{
    height: 100% !important;
  }
}

.SiteCustomization_ChangeLink__1Lx31 {
    font-size: 13px;
    color:var(--theme-color);
    cursor: pointer;
    display: flex;
}
.heartBtn{
  position: relative;
    z-index: 200;
    /* right: 9px; */
    padding: 2px;
    background: none;
    border: 0px;
    color: rgb(65 69 69);
    margin-left: 10px !important;
}

.shareBtn{
  position: relative;
    z-index: 200;
    /* right: 40px; */
    padding: 2px;
    background: none;
    border: 0px;
    color: rgb(65 69 69);
}

.badge.badge-pill.custom-badge{
  width: 40px !important;
  position: absolute !important;
	background:#30a11494;
  box-shadow: 0px 0px 5px 1px #69737c;
  /* transform: rotate3d(0, 0, 10, 330deg) !important; */
  top: 5px !important;
  left: 5px !important;
  color: #fff !important;
  font-weight: normal;
}

.starburst {
	display:block;
	width:15px;
	height:20px;
	background:#0d113c3d;
  box-shadow: 0px 0px 5px 1px #69737c;
  border-radius: 3px;
	/* -webkit-transform:rotate(-45deg);
	-moz-transform:rotate(-45deg);
	rotation:-45deg; */
	position: absolute;;
	top:5px;
	right:5px;
	text-align:center;
	text-decoration:none;
	color:#fff;
	font-weight:normal;
  font-size: 14px;
  font-family:Arial, sans-serif;
  z-index: 1000;
}
.starburst span.customStarburstSpan {
	display:block;
	width:25px;
	height:25px;
	background:#fe0;
	/* -webkit-transform:rotate(22.5deg);
	-moz-transform:rotate(22.5deg);
	rotation:22.5deg; */
}

.noticeOverlayHolder{
  display: none;
}

.noticeOverlay{
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.displayNone{
  display: none;
}

.displayBlock{
  display: block;
}

.noticeOverlayText{
  color: #fff !important;
  text-align: center;
  font-size: 13px !important;
}

/* .starburst:hover .tooltiptext {
  visibility: visible;
} */

/* ////////////// */

.featured-container.card{
  margin: 10px 10px 30px 5px;
  /* padding: 15px 20px 20px 20px; */
   background: #FFFFFF;
   box-shadow: 1px 10px 20px 3px rgb(167 167 167 / 27%);
   border: 0px;
   height: 87%;
}




.relevant-container.card{
  /* margin: 10px 20px 30px; */
  margin: 10px 5px 20px 5px;
  /* padding: 15px 20px 20px 20px; */
   background: #FFFFFF;
   /* box-shadow: 0px 13px 20px 2px rgb(167 167 167 / 27%); */
   box-shadow: 1px 3px 15px 2px rgb(167 167 167 / 27%);
   border: 0px;
   height: 87%;
}

.relevant-container.card .card-body{
  /* padding: 1.25rem 1.25rem 0 1.25rem !important; */
  padding: 5px 10px 0px 10px !important;
}

.featured-container.card .card-body{
  padding: 1.25rem 1.25rem 0 1.25rem !important;
}


.relevant-container .card p{
  color: #265376;
font-family: "Poppins";
font-size: 12px;
}

.card .text_combined_icon{
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.card .text_combined_icon img{
  width: 18px;
}

.card .text_combined_icon h5{
  margin: 0px;
}

.card .card-footer{
    padding-top: 2px !important;
    padding-bottom: 10px !important;
    background: none;
    border-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
}


a.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
    cursor: pointer;
}

.adventuresCarousel img.card-img-top{
  object-fit: cover;
}

.featured-container .adventuresCarousel img.card-img-top{
  height: 210px;
}
.relevant-container .adventuresCarousel img.card-img-top{
  height: 100px;
}

@media (min-width:425px) {
  .relevant-container li.react-multi-carousel-item.react-multi-carousel-item--active {
    /* width: calc(224px + (588 - 224) * ((100vw - 300px) / (2560 - 300))) !important; */
    max-width: 250px;
    /* width:250px; */
  }
}

@media (max-width: 768px) {
  .featured-container .adventuresCarousel img.card-img-top {
    height: 170px;
}
}

@media (max-width: 425px) {
  .relevant-container .adventuresCarousel img.card-img-top{
    height: 110px;
  }
}
/*
    DEMO STYLE
*/
body {
    /* font-family: 'Poppins', sans-serif; */
    /* background: #fafafa; */
}

/* p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.7em;
    color: #000;
} */

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    /* background: #fff; */
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    /* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); */
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

/* .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
} */

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #7386D5;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}


/* ---------------------------------------------------
    Notification Icon with Number
----------------------------------------------------- */

a.notif {
    position: relative;
    display: block;
    height: 35px;
    width: 35px;
    background: url(http://i.imgur.com/evpC48G.png);
    background-size: contain;
    text-decoration: none;
}

  .num {
    position: absolute;
    right: 6px;
    top: 2px;
    color: #fff;
    font-size: 14px;
  }

.modal-top{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10px;
    background: var(--theme-color);
    border-radius: 6px 6px 0px 0px;
  }
  
  .modal-header{
    border-bottom: 0px !important;
  }
  
  .modal-content{
    background-color: var(--theme-color-light2);
    border: 0px;
  
  }
  
  .modal-body{
    padding: 0px 50px;
    padding-bottom: 50px;
  }
  
  .modal-header button{
    background: none !important;
  }
  .modal-title button span{
    background-color: #D6BFF5;
  }
  
  .modal-dialog{
    max-width: 700px;
  }
  
  @media (max-width: 768px) {
    .modal-body{
      padding: 0px;
    }
  }
  .category-container {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }

  .category-select {
    width: 300px;

  }
#terms .ck.ck-editor__main .ck.ck-content.ck-editor__editable{
  height: 500px
}

#terms .ck.ck-editor__main .ck.ck-content.ck-editor__editable p{
  color: #000;
}

#singlePost_desc .ck.ck-editor__main .ck.ck-content.ck-editor__editable{
  height: 200px
}

#singlePost_desc .ck.ck-editor__main .ck.ck-content.ck-editor__editable p{
  color: #000;
}

#singlePost_wwli .ck.ck-editor__main .ck.ck-content.ck-editor__editable{
  height: 100px;
}

#singlePost_wwli .ck.ck-editor__main .ck.ck-content.ck-editor__editable p{
  color: #000;
}

.virtual-guru-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.virtual-guru-container .second-container {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 20px;
  overflow: auto;
}

.virtual-guru-container .first-container {
  display: flex;
  width: 100%;
  grid-gap: 50px;
  gap: 50px;
  align-items: center;
  padding: 0px 100px;
  margin-top: -70px;
}

.virtual-guru-container .first-container > div:first-of-type {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  text-align: center;
}

.virtual-guru-container .first-container > div:first-of-type h4 b {
  color: var(--theme-color-light);
}

.virtual-guru-container .first-container > div:first-of-type .img-container {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 9px 6px rgb(213 213 213);
  overflow: hidden;
  background-position:center;
  background-repeat: no-repeat;
  background-size:cover;
}

.virtual-guru-container .first-container .description {
  padding: 0px 30px 0px 0px;
  font-size: 1.2rem;
}

.virtual-guru-container
  .first-container
  > div:first-of-type
  .img-container
  img {
  display: flex;
  width: 100%;
  height: auto;
}


@media (max-width: 1080px) {
  .virtual-guru-container .first-container {
    flex-direction: column;
    padding: 0px 25px;
    margin-top: -20px;
  }

  .virtual-guru-container .second-container {
    padding: 0px 15px;
  }
  .virtual-guru-container .first-container .description { 
    padding: 0px;
    text-align: center;
  }
}

.custom-box {
  padding: 2rem 3rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.text-container {
  display: flex;
  justify-content: start;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.info-box {
  display: flex;
  align-items: end;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: start;
}

.tables-box {
  display: flex;
  justify-content: space-between;
  align-items: start;
  grid-gap: 2rem;
  gap: 2rem;
  width: 100%;
  margin: 2.5rem 0;
}
.custom-box {
  padding: 2rem 3rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
}
.text-container {
  display: flex;
  justify-content: start;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.info-box {
  display: flex;
  align-items: end;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: start;
}
.mood-box {
  padding: 2rem 3rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
}
.mood-box-sections {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10rem;
  gap: 10rem;
  align-items: center;
}
.mood-text {
  font-size: large;
}
.heading-margin {
  margin: 3rem 0 0 0;
}
.bothering-section {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

.custom-skeleton {
  width: 50%;
  height: 10px;
  background-color: #f0f0f0; /* Light gray background color */
  animation: loading 1.5s infinite ease-in-out; /* Apply the animation */
}

@keyframes loading {
  0% {
    opacity: 0.5; /* Start with a semi-transparent color */
  }
  50% {
    opacity: 1; /* Transition to fully opaque */
  }
  100% {
    opacity: 0.5; /* Transition back to semi-transparent */
  }
}

.skeleton {
  background-color: #f0f0f0; /* Light gray background color */
  animation: loading 1.5s infinite ease-in-out; /* Apply the animation */
}

@keyframes loading {
  0% {
    opacity: 0.5; /* Start with a semi-transparent color */
  }
  50% {
    opacity: 1; /* Transition to fully opaque */
  }
  100% {
    opacity: 0.5; /* Transition back to semi-transparent */
  }
}

