.each-guru {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  /* min-width: 100%; */
}

.each-guru span {
  max-width: 150px;
  text-align: center;
}

.each-guru:hover span {
  text-decoration: underline;
}

.each-guru .img-container {
  background-position:center;
  background-repeat: no-repeat;
  background-size:cover;
}

.each-guru.small .img-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 9px 6px rgb(213 213 213);
  overflow: hidden;

}

.each-guru.large .img-container {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 9px 6px rgb(213 213 213);
  overflow: hidden;
}

.each-guru .img-container img {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 50%;
}
