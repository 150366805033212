#smallFooter .sm_footer_svg1_sm{
  position: absolute;
right: 0;
top: -50%;
width: 18%;
overflow: hidden;
opacity: 40%;
}

footer#smallFooter{
  margin-top: 5%;
  display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

footer#smallFooter{
  color: #542B96;
  font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
}

@media (max-width: 768px) {
  footer#smallFooter{
    margin-top: 15%;
  }
}
