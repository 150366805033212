footer{
  /* background-image: url("./assets/index/footer_svg_background.svg"); */
  height: 80vh;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  position: relative;
}

footer .footer_items_container{
  width: 65%;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
}

footer .footer_items_container .footer_items_top_container{
  margin-bottom: 5%;
}

footer .footer_items_container .row div.col-4{
  display: flex;
  justify-content: center;
}

footer .footer_items_container h5{
  color: #fff;
}

footer .footer_items_container p{
  color: #fff;
}

footer .footer_items_container h5{
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  font-weight: 500;
}

footer .footer_items_container p{
  font-family: "Poppins";
  font-size: 16px !important;
  font-weight: 400;
  margin-bottom: 13px !important;
}

footer .footer_items_container h5.title{
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 25px;
}

footer .footer_items_container .logo img{
  width: 60%;
}

footer .footer_items_container .social_icon{
  margin-bottom: 20px;
  text-align: center;
}
footer .footer_items_container .social_icon a{
  margin: 0px 5px;
}
footer .footer_items_container .social_icon img{
  width: 38px;
}

footer .footer_items_container .terms{
  font-family: "Poppins";
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5%;
}

footer .footer_items_container .terms a{
  color: #fff;
}

footer .footer_items_container .copy_rights p{
  font-family: "Poppins";
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 0px !important;
}

/* memphis 1 */
footer .footer_left_svg1{
  position: absolute;
  width: 7%;
  left: 2%;
  top: 20%;
  opacity: 0.4;
}

footer .footer_left_svg2{
  position: absolute;
  top: 58%;
  left: 0%;
  width: 10%;
  opacity: 0.4;
}

footer .footer_left_svg3{
  position: absolute;
  bottom: 0%;
  left: 3%;
  width: 10%;
  opacity: 0.4;
}

footer .footer_right_svg1{
  position: absolute;
  right: 11%;
  top: 10%;
  width: 6%;
  opacity: 0.4;
}

footer .footer_right_svg2{
  position: absolute;
  top: 33%;
  right: -2%;
  width: 12%;
  opacity: 0.4;
}

footer .footer_right_svg3{
  position: absolute;
  bottom: 0%;
  right: 2%;
  width: 15%;
  opacity: 0.4;
}

#mobile_view_footer .footer_items_container .logo img{
  width: 50%;
}

#mobile_view_footer .footer_items_container{
  width: 75%;
}

/* xs (for phones - screens less than 768px wide) */
@media (max-width: 768px) {


  #pricing_section .container{
    width: 100% !important;
  }

  footer .footer_items_container .logo img{
    width: 90%;
  }

  #mobile_view_footer .footer_items_container .logo img{
    width: 45%;
  }

  #mobile_view_footer .footer_items_container{
    bottom: 17%;
  }

  /* memphis sm right */
  #mobile_view_footer .footer_left_svg1_sm{
    position: absolute;
    bottom: 0%;
    left: 10%;
    width: 14%;
  }
  #mobile_view_footer .footer_right_svg2_sm{
    position: absolute;
    top: 44%;
    right: 11%;
    width: 16%;
  }
  #mobile_view_footer .footer_right_svg2_sm{
    position: absolute;
    bottom: 50%%;
    right: 15%;
    width: 12%;
  }

  footer .footer_items_container .social_icon img{
    width: 25px;
  }


  footer .footer_items_container h5{
    font-size: 12px;
  }

  footer .footer_items_container p{
    font-size: 12px;
  }

  footer .footer_items_container h5.title{
    font-size: 12px;
  }

}
/* sm (for tablets - screens equal to or greater than 768px wide) */
@media (min-width: 768px) and (max-width: 992px) {
  #pricing_section .container{
    width: 90% !important;
  }

  footer .footer_items_container .social_icon img{
    width: 30px;
  }

  footer .footer_items_container .logo img{
    width: 80%;
  }


  footer .footer_items_container h5{
    font-size: 14px;
  }

  footer .footer_items_container p{
    font-size: 14px;
  }

  footer .footer_items_container h5.title{
    font-size: 14px;
  }


  /* memphis 1 */
  footer .footer_left_svg1{
    position: absolute;
    width: 7%;
    left: 2%;
    top: 12%;
  }

  footer .footer_left_svg2{
    position: absolute;
    top: 39%;
    left: 0%;
    width: 14%;
  }

  footer .footer_left_svg3{
    position: absolute;
    bottom: 4%;
    left: 3%;
    width: 18%;
  }

  footer .footer_right_svg1{
    position: absolute;
    right: 11%;
    top: 10%;
    width: 10%;
  }

  footer .footer_right_svg2{
    position: absolute;
    top: 33%;
    right: -2%;
    width: 16%;
  }

  footer .footer_right_svg3{
    position: absolute;
    bottom: 0%;
    right: 2%;
    width: 21%;
  }




}
/* md (for small laptops - screens equal to or greater than 992px wide) */
@media (min-width: 992px) and (max-width: 1200px) {

}
/* lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media (min-width: 1200px) {

}

@media (max-width:500px) {
  footer .footer_items_container .social_icon img{
    width: 18px;
  }
}

@media (max-width:350px) {
  footer .footer_items_container .social_icon img{
    width: 14px;
  }

}
