.mood-check-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  padding: 40px 50px;
  margin-bottom: 20px;
}

.option-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.option-group {
  display: flex;
  align-items: center;
  grid-gap: 30px;
  gap: 17px;
  flex-wrap: wrap;
  justify-content: center;
}

.option-group.with-counts {
  gap: 20px;
}

.option-group.small-icons {
  gap: 5px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  border: 1px solidvar(--theme-color);
  padding: 2px 8px;
  border-radius: 10px;
}

.option span {
  font-size: 12px;
}

.option.active {
  background: var(--theme-color);
  color: white;
}
.option-with-image .count {
  /* background:var(--theme-color); */
  color: var(--theme-color);
  /* width: 30px;
    height: 30px;
    border-radius: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-with-image {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 3px;
}

.option-with-image.active {
  border-bottom: 3px solid var(--theme-color);
}

.option-with-image img {
  width: 50px;
}

.option-with-image.small img {
  width: 25px;
}

.option-with-image span {
  font-size: 12px;
  color: unset !important;
}

.option-with-image .color-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.option-with-image.small .color-container {
  width: 25px;
  height: 25px;
}

@media (max-width: 768px) {
  .mood-check-container {
    padding: 15px;
  }
}
