.gurus-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 80px;
  margin-top: 20px;
}

.gurus-section h3 {
  margin: 0px 15px !important;
  color: #1E2864;
  text-align: center;
}

.gurus-list {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  width: 600px;
}

.gurus-list.web-view {
 flex-wrap: nowrap;
}

.gurus-list.web-view .swiper-slide {
  width: 150px !important;
}



.gurus-list .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 0px;
}
.gurus-list .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 0px;
}

.gurus-list .small {
  max-width: 500px;
}

.each-guru {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.each-guru span {
  max-width: 150px;
  text-align: center;
}

.each-guru:hover span {
  text-decoration: underline;
}

.each-guru .img-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 6px 0px rgb(213 213 213);
}

.each-guru .img-container img {
  display: flex;
  width: 100%;
  height: auto;
}


@media (max-width: 768px) {
  .gurus-list {
    width: calc(100% - 70px);
  }
}